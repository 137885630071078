import { colors } from './colors';
import React, { CSSProperties } from 'react';
import styled, { ThemeProps } from 'styled-components';
import { Spacing } from './spacing';
import { ThemedIcon, ThemedIconType } from './ThemedIcon';
import { Theme } from './types';

interface DoubleCircledIconProps {
  type: ThemedIconType;
  containerStyle?: CSSProperties;
}

interface SingleCircledIconProps {
  type: ThemedIconType;
  containerStyle?: CSSProperties;
  iconStyle?: CSSProperties;
  tint?: string;
  onClick?: () => void;
}

const LargeCircle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${colors.primary25};
  width: 64px;
  height: 64px;
  border-radius: 32px;
`;

const SmallCircle = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: ${colors.primary50};
`;

const SingleCircle = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: ${Spacing.lg}px;
  background-color: ${(props: ThemeProps<Theme>) => props.theme.neutral100};
  flex-shrink: 0;
`;

export const DoubleCircledIcon = ({ type, containerStyle }: DoubleCircledIconProps) => {
  return (
    <LargeCircle style={containerStyle}>
      <SmallCircle>
        <ThemedIcon type={type} tint={colors.primary500} />
      </SmallCircle>
    </LargeCircle>
  );
};

export const SingleCircledIcon = ({
  type,
  containerStyle,
  iconStyle,
  tint,
  onClick,
}: SingleCircledIconProps) => {
  return (
    <SingleCircle style={containerStyle} onClick={onClick}>
      <ThemedIcon type={type} tint={tint ?? colors.neutral400} style={iconStyle}></ThemedIcon>
    </SingleCircle>
  );
};
