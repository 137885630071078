import React from 'react';
import styled, { ThemeProps } from 'styled-components';
import { ProfileImage } from 'components/ProfileImage';
import { Card } from 'styleguide/Card';
import { View } from 'styleguide/View';
import { TextLG, TextSMRegular } from 'styleguide/Texts';
import { FollowButtonPrimary } from 'components/FollowButton';
import { ProfileStat } from 'components/ProfileStat';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { SecondaryButton } from 'styleguide/Buttons';
import router from 'next/router';
import { colors } from 'styleguide/colors';
import { formatLink } from 'utils/misc';
import { Theme } from 'styleguide/types';
import { FlexRow } from 'styleguide/Row';

interface ProfileHeaderProps {
  username: string;
  fullname?: string;
  profilePic?: string;
  imageUrls?: string[];
  link?: string;
  workoutCount: string;
  followerCount: string;
  onFollowersPress?: () => void;
  followingCount: string;
  onFollowingPress?: () => void;
  description?: string;
  showFollowButton: boolean;
  isOwner?: boolean;
}

export const ProfileHeader = (props: ProfileHeaderProps) => {
  return (
    <>
      <DesktopHeader {...props} />
      <MobileHeader {...props} />
    </>
  );
};

const DesktopContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const StyledImage = styled.img<{ position: 'first' | 'middle' | 'last' }>`
  display: flex;
  width: 100%;
  min-width: 100px;
  border-top-left-radius: ${props => (props.position === 'first' ? `${CornerRadius.sm}px` : 0)};
  border-bottom-left-radius: ${props => (props.position === 'first' ? `${CornerRadius.sm}px` : 0)};
  border-bottom-right-radius: ${props => (props.position === 'last' ? `${CornerRadius.sm}px` : 0)};
  border-top-right-radius: ${props => (props.position === 'last' ? `${CornerRadius.sm}px` : 0)};
`;

const ImagePlaceholder = styled.div<{ position: 'middle' | 'last' }>`
  display: flex;
  width: 100%;
  align-self: stretch;
  border-bottom-right-radius: ${props => (props.position === 'last' ? `${CornerRadius.sm}px` : 0)};
  border-top-right-radius: ${props => (props.position === 'last' ? `${CornerRadius.sm}px` : 0)};
  background-color: ${(props: ThemeProps<Theme>) => props.theme.neutral100};
`;

const ProfileImageContainer = styled.div<{ radius: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props: ThemeProps<Theme>) => props.theme.secondary};
  border-radius: ${props => props.radius + 2}px;
  height: ${props => props.radius * 2 + 4}px;
  width: ${props => props.radius * 2 + 4}px;
`;

const StatsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${Spacing.md}px;
`;

const DesktopHeader = ({
  profilePic,
  fullname,
  username,
  link,
  imageUrls,
  isOwner,
  workoutCount,
  followerCount,
  onFollowersPress,
  followingCount,
  onFollowingPress,
  description,
  showFollowButton,
}: ProfileHeaderProps) => {
  const hasWorkoutImages = !!imageUrls && imageUrls.length !== 0;

  return (
    <DesktopContainer>
      {hasWorkoutImages && (
        <FlexRow style={{ gap: 2, padding: Spacing.sm, marginBottom: -55 }}>
          {imageUrls
            .concat(new Array(5).fill('empty'))
            .slice(0, 5)
            .map((url, index, array) =>
              url !== 'empty' ? (
                <StyledImage
                  src={url}
                  key={index}
                  position={index === 0 ? 'first' : index === array.length - 1 ? 'last' : 'middle'}
                ></StyledImage>
              ) : (
                <ImagePlaceholder
                  key={index}
                  position={index === array.length - 1 ? 'last' : 'middle'}
                ></ImagePlaceholder>
              ),
            )}
        </FlexRow>
      )}
      <View style={{ padding: Spacing.lg, gap: Spacing.md }}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: hasWorkoutImages ? 'flex-end' : 'center',
            gap: Spacing.lg,
          }}
        >
          <ProfileImageContainer radius={62}>
            <ProfileImage source={profilePic} diameter={122} />
          </ProfileImageContainer>

          <View style={{ gap: Spacing.xs }}>
            <View style={{ flexDirection: 'row', alignItems: 'center', gap: Spacing.md }}>
              <TextLG>{username}</TextLG>
              {isOwner && (
                <SecondaryButton
                  title={'Edit Profile'}
                  onClick={() => {
                    router.push('/settings?profile');
                  }}
                ></SecondaryButton>
              )}
              {!isOwner && showFollowButton && (
                <FollowButtonPrimary buttonStyle={{ width: 75 }} username={username} />
              )}
            </View>
            <TextSMRegular>{fullname}</TextSMRegular>
            <StatsContainer>
              <ProfileStat label={'Workouts'} count={`${workoutCount}`} layout="horizontal" />
              <ProfileStat
                label={'Followers'}
                count={`${followerCount}`}
                onClick={onFollowersPress}
                layout="horizontal"
              />
              <ProfileStat
                label={'Following'}
                count={`${followingCount}`}
                onClick={onFollowingPress}
                layout="horizontal"
              />
            </StatsContainer>
          </View>
        </View>
        {description && (
          <TextSMRegular type="secondary" style={{ whiteSpace: 'pre-wrap' }}>
            {description}
          </TextSMRegular>
        )}
        {link && (
          <a
            href={formatLink(link)}
            style={{ textDecoration: 'none' }}
            target="_blank"
            rel="noreferrer"
          >
            <TextSMRegular style={{ color: colors.primary500, whiteSpace: 'pre-wrap' }}>
              {link}
            </TextSMRegular>
          </a>
        )}
      </View>
    </DesktopContainer>
  );
};

const MobileContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    display: none;
  }
`;

const MobileHeader = ({
  profilePic,
  username,
  fullname,
  link,
  imageUrls,
  workoutCount,
  followerCount,
  onFollowersPress,
  followingCount,
  onFollowingPress,
  description,
  showFollowButton,
  isOwner,
}: ProfileHeaderProps) => {
  return (
    <MobileContainer>
      {!!imageUrls && imageUrls.length !== 0 && (
        <FlexRow style={{ gap: 2, padding: Spacing.sm, marginBottom: -45 }}>
          {imageUrls
            .concat(new Array(3).fill('empty'))
            .slice(0, 3)
            .map((url, index, array) =>
              url !== 'empty' ? (
                <StyledImage
                  src={url}
                  key={index}
                  position={index === 0 ? 'first' : index === array.length - 1 ? 'last' : 'middle'}
                ></StyledImage>
              ) : (
                <ImagePlaceholder
                  key={index}
                  position={index === array.length - 1 ? 'last' : 'middle'}
                ></ImagePlaceholder>
              ),
            )}
        </FlexRow>
      )}
      <View style={{ padding: Spacing.lg, gap: Spacing.md }}>
        <View style={{ flexDirection: 'row', alignItems: 'flex-end', gap: Spacing.lg }}>
          <ProfileImageContainer radius={36}>
            <ProfileImage source={profilePic} diameter={72} />
          </ProfileImageContainer>
          <View style={{ gap: Spacing.xs }}>
            <View style={{ flexDirection: 'row', alignItems: 'center', gap: Spacing.md }}>
              <TextLG>{username}</TextLG>
            </View>
            <TextSMRegular>{fullname}</TextSMRegular>
          </View>
        </View>
        <StatsContainer>
          <ProfileStat label={'Workouts'} count={`${workoutCount}`} />
          <ProfileStat label={'Followers'} count={`${followerCount}`} onClick={onFollowersPress} />
          <ProfileStat label={'Following'} count={`${followingCount}`} onClick={onFollowingPress} />
        </StatsContainer>

        {isOwner && (
          <SecondaryButton
            title={'Edit Profile'}
            onClick={() => {
              router.push('/settings?profile');
            }}
          ></SecondaryButton>
        )}
        {!isOwner && showFollowButton && (
          <FollowButtonPrimary buttonStyle={{ width: '100%' }} username={username} />
        )}

        {description && (
          <TextSMRegular type="secondary" style={{ whiteSpace: 'pre-wrap' }}>
            {description}
          </TextSMRegular>
        )}

        {link && (
          <a
            href={formatLink(link)}
            style={{ textDecoration: 'none' }}
            target="_blank"
            rel="noreferrer"
          >
            <TextSMRegular style={{ color: colors.primary500, whiteSpace: 'pre-wrap' }}>
              {link}
            </TextSMRegular>
          </a>
        )}
      </View>
    </MobileContainer>
  );
};
