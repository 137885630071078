import React, { memo, useEffect, useState } from 'react';
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';
import styled, { ThemeProps } from 'styled-components';
import { TextXSRegular, TextMDRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { colors } from 'styleguide/colors';
import fonts from 'styleguide/fonts';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { ClipLoader } from 'react-spinners';
import { roundToWholeNumber, secondsToWordFormatMinutes } from 'hevy-shared';
import { themeState } from 'styleguide/theme';
import { Theme } from 'styleguide/types';
import { ResponsiveGraphContainer } from 'components/ResponsiveGraphContainer';
import dayjs from 'dayjs';

const BAR_CHART_HEIGHT = 180;
const CHART_TOOLTIP_WIDTH = 120;
const X_AXIS_HEIGHT = 30;

const Container = styled(View)`
  flex: 1;
`;

export interface BarGraphDataPoint {
  date: string;
  value: number;
}

export type BarGraphData = BarGraphDataPoint[];

interface ProfileBarChartProps {
  data: BarGraphData;
  unit: string;
  isLoading?: boolean;
  isWeekView?: boolean;
}

export const ProfileBarChart = memo(
  ({ data, unit, isLoading, isWeekView }: ProfileBarChartProps) => {
    const [valuePositionData, setposData] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

    // We use these min/max state values so we can calculate the tooltip position
    const [dataMaxValue, setDataMax] = useState(0);
    const [dataMinValue, setDataMin] = useState(0);

    // We keep track of whether the mouse is in the container so we can
    // show the tooltip till the mouse is completely outside the chart area
    const [isMouseInsideChartContainer, setIsMouseInsideChartContainer] = useState(false);
    return (
      <Container
        onMouseLeave={() => {
          setIsMouseInsideChartContainer(false);
        }}
        onMouseEnter={() => {
          setIsMouseInsideChartContainer(true);
        }}
      >
        {isLoading ? (
          <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <ClipLoader size={20} color={colors.primary500} loading={true} />
          </View>
        ) : (
          <ResponsiveGraphContainer>
            <BarChart
              data={data}
              style={{ height: BAR_CHART_HEIGHT }}
              barSize={Spacing.md}
              // Helps ensure selected dots don't clip
              margin={{ top: Spacing.lg, right: Spacing.lg }}
              onMouseMove={data => {
                if (data.activeCoordinate && data.activePayload?.[0]?.payload.value !== null) {
                  const activePayloadValue = data.activePayload?.[0]?.payload.value;

                  let percentOfChartHeight = 0;
                  if (dataMaxValue === dataMinValue) {
                    // There is only one value
                    percentOfChartHeight = 0.5;
                  } else {
                    // Okay, for some reason recharts doesn't give us the "y" value of the
                    // currently active POINT (it gives us the mouse location), so
                    // let's do some math to find where the point is:
                    percentOfChartHeight =
                      (activePayloadValue - dataMinValue) / (dataMaxValue - dataMinValue);
                  }

                  // -40 here because there is some padding around the chart. I'm not exactly
                  // sure what the amount is, but this seems to position the tooltip
                  // consistently vertically
                  let y = -((BAR_CHART_HEIGHT - X_AXIS_HEIGHT) * percentOfChartHeight);

                  // +80 puts the tooltip in the right absolute position compared
                  // with the top of the bar
                  y = y + 80;
                  setposData({ x: data.activeCoordinate.x - CHART_TOOLTIP_WIDTH / 2, y: y });
                }
              }}
            >
              <CartesianGrid
                vertical={false}
                stroke={themeState.current.neutral200}
                strokeDasharray="3 3"
              />
              <XAxis
                // Required to keep the text values from clipping
                height={X_AXIS_HEIGHT}
                dataKey="date"
                tickLine={false}
                axisLine={{ strokeWidth: 1, stroke: themeState.current.neutral200 }}
                minTickGap={0}
                tickMargin={Spacing.md}
                color={colors.neutral500}
                tick={<CustomizedXAxisLabel x={0} y={0} payload={undefined} />}
              />
              <YAxis
                scale={'linear'}
                domain={[
                  () => {
                    setDataMin(0); // We need this to calculate tooltip position
                    return 0;
                  },
                  (dataMax: number) => {
                    setDataMax(dataMax); // We need this to calculate tooltip position
                    return dataMax;
                  },
                ]}
                axisLine={false}
                tickLine={false}
                tickMargin={Spacing.md}
                allowDecimals={false}
                tick={<CustomizedAxisLabel x={0} y={0} payload={undefined} />}
              />
              <Tooltip
                cursor={false}
                trigger="hover"
                position={{ x: valuePositionData?.x ?? 0, y: valuePositionData?.y ?? 0 }}
                allowEscapeViewBox={{ x: true, y: true }}
                // This keeps the OS from drawing a "dialog selected" box around the tooltip
                wrapperStyle={{ outline: '0px', visibility: 'visible' }}
                content={
                  // Actual props (except unit and isMouseInsideContainer) will be passed by Tooltip under the hood
                  <ChartTooltip
                    isMouseInsideContainer={isMouseInsideChartContainer}
                    unit={unit}
                    payload={undefined}
                    active={false}
                    label=""
                    isWeekView={isWeekView}
                  />
                }
              />
              <Bar dataKey="value" fill={colors.primary500} isAnimationActive={true} />
            </BarChart>
          </ResponsiveGraphContainer>
        )}
      </Container>
    );
  },
);

const DownTriangle = styled.div`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid ${(props: ThemeProps<Theme>) => props.theme.neutral100};
  align-self: center;
`;

const ChartTooltip = ({
  isMouseInsideContainer,
  payload,
  label: date,
  unit,
  isWeekView,
}: {
  isMouseInsideContainer: boolean;
  active: boolean;
  payload: any;
  label: string;
  unit: string;
  isWeekView?: boolean;
}) => {
  // Okay, so Recharts clears the data when the mouse goes outside the chart, but we want
  // to keep rendering the tooltip till the mouse goes outside the CONTAINER. We keep track
  // of the last value here so we can render until isMouseInsideContainer goes false.
  const [lastPayload, setLastPayload] = useState<any>({});
  useEffect(() => {
    if (payload && payload.length > 0) {
      setLastPayload(payload);
    }
  }, [payload]);
  if (lastPayload && lastPayload.length && lastPayload[0].value !== 0 && isMouseInsideContainer) {
    return (
      <View
        onClick={event => {
          // Ensure clicking here doesn't select another point on the chart
          event.stopPropagation();
        }}
      >
        <View
          style={{
            backgroundColor: themeState.current.neutral100,
            borderRadius: CornerRadius.md,
            minWidth: CHART_TOOLTIP_WIDTH,
          }}
        >
          <View style={{ padding: Spacing.md, paddingBottom: Spacing.xs }}>
            <TextXSRegular>
              {!!isWeekView
                ? `${date} - ${dayjs(date, 'MMM D').add(1, 'week').format('MMM D')}`
                : date}
            </TextXSRegular>
            <TextMDRegular type="secondary">
              {unit === 'hr'
                ? secondsToWordFormatMinutes(lastPayload[0].value as number).toLocaleString()
                : `${roundToWholeNumber(lastPayload[0].value as number).toLocaleString()} ${unit}`}
            </TextMDRegular>
          </View>
        </View>
        <DownTriangle />
      </View>
    );
  }

  return null;
};

const CustomizedAxisLabel = (props: { x: number; y: number; payload: any }) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={4}
        textAnchor="middle"
        fill={colors.neutral500}
        fontFamily={fonts.regular.family}
        fontWeight={fonts.regular.weight}
        fontSize={12}
      >
        {payload.value}
      </text>
    </g>
  );
};
const CustomizedXAxisLabel = (props: { x: number; y: number; payload: any }) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={4}
        textAnchor="middle"
        fill={colors.neutral500}
        fontFamily={fonts.regular.family}
        fontWeight={fonts.regular.weight}
        fontSize={12}
      >
        {payload.value}
      </text>
    </g>
  );
};
